.prompt-container {
    display: flex;
    align-items: center;
    line-height: 1.5rem;
}

.triangle {
    border-left: var(--fs-normal) solid var(--primary);
    border-bottom: var(--fs-normal) solid transparent;
    border-top: var(--fs-normal) solid transparent;
}

.prompt-text {
    display: inline-block;
    white-space: nowrap;
    background: var(--primary);
    font-weight: 900;
    line-height: 1.3rem; 
}
