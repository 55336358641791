.home-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.terminal {
    background: linear-gradient(135deg,var(--grey-80),var(--grey-80));
    justify-content: center;
    align-items: center;
    font-size: var(--fs-normal);
    width: var(--content-width);
    min-height: 50vh;
    border-radius: var(--border-radius);
    border: var(--grey-70) solid var(--border-width);
}

.terminal-bar {
    display: flex;
    justify-self: flex-start;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--middle);
    width: 100%;
    height: 45px;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    border-bottom: var(--grey-70) solid var(--border-width);
}

.dot-container {
    display: flex;
    justify-content: center;
    gap: 1vw;
    margin-right: 3vw;
}

.dot {
    border-radius: 50%;
    background: var(--grey-60);
    width: 0.75rem;
    height: 0.75rem; 
}

.terminal-content {
    display: flex;
    flex-direction: column;
    padding: var(--text-pad);
    font-family: "SuisseIntlMono-Regular";
}

.prompt {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    text-align: left;
    line-height: 1.2rem;

}

.triangle {
    border-left: var(--fs-medium) solid var(--accent-2);
    border-bottom: var(--fs-medium) solid transparent;
    border-top: var(--fs-medium) solid transparent;
}

.prompt-text {
    display: flex;
    background: var(--accent-2);
    color: var(--grey-100);
    font-weight: 900;
    padding: 0.5vw;
    text-align: center;
    line-height: var(--normal);
}


