.skill-container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-top: 5vh;
  z-index: 1;
  background-image: linear-gradient(0deg,var(--grey-100),var(--elevation-1));
  border-radius: var(--border-radius);
  padding: var(--text-pad);
}

.skill-content {
  border-top: var(--lines) solid var(--grey-00);
  margin-top: var(--text-pad);
  padding: var(--text-pad) 0 var(--text-pad) 0;
}

.skill-wrapper {
  padding: 3vw 0 3vw 0;
}

.bar-wrapper {
  display: flex;
  justify-content: flex-start;
  position: relative;
  margin-top: 0.5rem;
}

.background-bar {
  position: absolute;
  z-index: 2;
  width: 100%;
  background: var(--grey-80);
  height: var(--fs-big);
  border-radius: calc(var(--border-radius)*0.5);
}

.skill-bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: var(--fs-big);
  background: linear-gradient(to right,var(--primary-darkest), var(--primary-dark), var(--primary-light), var(--primary-lightest));
  border-radius: calc(var(--border-radius)*0.5);
  z-index: 3;
}

.skill-bar p {
  margin-right: 1rem;
  font-family: SuisseIntlMono-Bold;
}

