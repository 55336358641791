.header-container {
    top: 0;
    left:0;
    height: 10vh;
    display: flex;
    width: 100vw;
    position: fixed;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    backdrop-filter: blur(2px);

}

.header-logo {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    margin-left: var(--side-margin);
    font-size: var(--fs-small);
    color: var(--grey-40);
}

.header-logo-left {
    font-family: 'SuisseIntl-Light';

}

.header-logo-right {
    font-family: 'SuisseIntlMono-Bold';
}

.header-container h1{
    text-align: left;
}
