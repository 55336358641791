@font-face {
  font-family: 'SuisseIntlMono-Light';
  src: local('SuisseIntlMono-Light'),
   url('./assets/fonts/SuisseIntlMono/SuisseIntlMono-Light.otf') format('opentype');
}

@font-face {
  font-family: 'SuisseIntlMono-Regular';
  src: local('SuisseIntlMono-Regular'),
  url('./assets/fonts/SuisseIntlMono/SuisseIntlMono-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SuisseIntlMono-Bold';
  src: local('SuisseIntlMono-Bold'),
  url('./assets/fonts/SuisseIntlMono/SuisseIntlMono-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SuisseIntl-Light';
  src: local('SuisseIntl-Light'),
  url('./assets/fonts/rest/SuisseIntl-Light.otf') format('opentype');
}

@font-face {
  font-family: 'SuisseIntl-Regular';
  src: local('SuisseIntl-Regular'),
  url('./assets/fonts/rest/SuisseIntl-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'SuisseIntl-Bold';
  src: local('SuisseIntl-Bold'),
  url('./assets/fonts/rest/SuisseIntl-Bold.otf') format('opentype');
}


:root {
  --primary: #63e;
  --primary-pale: #dfd2ff;
  --primary-lightest: #b49afb;
  --primary-light: #8e64ff;
  --primary-dark: #4213c1;
  --primary-darkest: #2a0885;
  --grey-00: #fff;
  --grey-10: #f4f4ff;
  --grey-20: #ececfb;
  --grey-30: #dfe1f4;
  --grey-40: #babcd2;
  --grey-50: #9fa2b9;
  --grey-60: #5e6077;
  --grey-70: #383a4d;
  --grey-80: #2a2b3a;
  --grey-90: #171825;
  --grey-100: #010314;
  --elevation-1: #292d39;
  --elevation-2: #181c20;
  --elevation-3: #373c4b;
  --accent-1: #0066dc;
  --accent-2: #007bff;
  --accent-3: #3c93ff;
  --highlight-1: #535760;
  --highlight-2: #8c92a4;
  --highlight-3: #fefefe;

  --border-radius: 1.5rem;
  --border-width: 1px;
  --border-color: var(--grey-80);
  --btn-radius: 4.5rem;
  --lines: 0.1rem;
  --side-margin: 10vw;
  --content-width: 80vw;
}

html, body {
  box-sizing: border-box;
  font-family: 'SuisseIntl-Light';
  padding: 0;
  margin: 0;
  background: var(--grey-100);
  color: var(--grey-20);
  width: 100vw;
  height: 100vh;
  -webkit-tap-highlight-color: transparent;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10vh 0vh;
  width: 100vw;
  min-height: 100%;
  padding: 8vh 0 0 0;
}

h1 {
  font-family: 'SuisseIntl-Regular';
  font-size: var(--fs-huge);
  padding: 0;
  margin: 0 0 var(--header-pad) 0;
  line-height: var(--fs-huge);
}

h2 {
  font-family: 'SuisseIntl-Regular';
  font-size: var(--fs-big);
  padding: 0;
  margin: 0;
  line-height: calc(var(--fs-big)*1.2);
}

h3 {
  font-family: 'SuisseIntl-Regular';
  font-size: var(--fs-medium);
  padding: 0;
  margin: 0;
  line-height: var(--fs-medium);
}

h4 {
  font-family: 'SuisseIntl-Regular';
  font-size: var(--fs-normal);
  padding: 0;
  margin: 0;
  line-height: var(--fs-normal);
}

h5 {
  font-family: 'SuisseIntl-Regular';
  font-size: var(--fs-small);
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

article {
  display: flex;
  flex-direction: column;
  font-size: var(--fs-normal);
  line-height: 1.5;
  color: var(--grey-00);
  margin: 5vh 0 5vh 0;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

@media(min-width: 320px){
  :root {
  --text-pad: 5vw;
  --box-shadow: 2vw 2vw var(--grey-80);
  --fs-huge: 3rem;
  --fs-big: 1.75rem;
  --fs-medium: 1.25rem;
  --fs-normal: 1rem;
  --fs-small: 0.75rem;
  --fs-tiny: 0.5rem;
  }
}

@media(min-width: 480px){
  :root {
  --text-pad: 5vw;
  --box-shadow: 2vw 2vw var(--grey-80);
  --fs-huge: 3.5rem;
  --fs-big: 1.75rem;
  --fs-medium: 1.25rem;
  --fs-normal: 1rem;
  --fs-small: 0.75rem;
  --fs-tiny: 0.5rem;
  }
}

@media(min-width: 768px){
  :root {
  --text-pad: 5vw;
  --box-shadow: 1vw 1vw var(--grey-80);
  --fs-huge: 6rem;
  --fs-big: 3rem;
  --fs-medium: 2rem;
  --fs-normal: 1.25rem;
  --fs-small: 1rem;
  --fs-tiny: 0.75rem;
  }
}

@media(min-width: 1024px){
  :root {
  --text-pad: 3vw;
  --box-shadow: 0.75vw 0.75vw var(--grey-80);
  --fs-huge: 6rem;
  --fs-big: 3rem;
  --fs-medium: 2rem;
  --fs-normal: 1.5rem;
  --fs-small: 1rem;
  --fs-tiny: 0.75rem;
  }
}

@media(min-width: 1200px){
  :root {
  --text-pad: 3vw;
  --box-shadow: 0.75vw 0.75vw var(--grey-80);
  --fs-huge: 6rem;
  --fs-big: 3rem;
  --fs-medium: 2rem;
  --fs-normal: 1.5rem;
  --fs-small: 1rem;
  --fs-tiny: 0.75rem;
  }
}

