.tech-wrapper {
  display: flex;
  flex-direction: column;
  width: var(--medium-content-width);
  margin-top: 5vh;
  border-radius: var(--border-radius);
  justify-content: space-between;
  background-image: linear-gradient(0deg,var(--grey-100),var(--accent-1));
  border-radius: var(--border-radius);
  padding: var(--text-pad);
}

.tech-content {
  display: flex;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 5vh;
  margin-top: var(--text-pad);
  padding: var(--text-pad) 0 var(--text-pad) 0;
  border-top: var(--lines) solid var(--grey-00);
}

.tech-card {
  display: flex;
  flex-direction: column;
  background: var(--elevation-1);
  border-radius: var(--border-radius);
}

.tech-card-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-00);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  margin-bottom: 1rem;
}

.tech-card-text {
  display: flex;
  flex-direction: column;
  white-space: normal;
}

.tech-card-text h3 {
  text-align: center;
}

.tech-icon {
  display: flex;
  color: var(--elevation-1);
}

@media(min-width: 320px){
  .tech-card {
    min-width: 10rem;
    min-height: 17rem;
    margin: 0 20px 0 0;
  }

  .tech-card-top {
    height: 10rem;
  }

  .tech-card-text {
    padding: 1rem 1rem 0 1rem;
    font-size: var(--fs-small);
  }

  .tech-card-text h3 {
    padding: 0.05rem 0.1rem 0.05rem 0.1rem;
  }

  .tech-icon {
    font-size: 5rem;
  }
}

@media(min-width: 480px){
  .tech-card {
    min-width: 10rem;
    min-height: 17rem;
    margin: 0 20px 0 0;
  }

  .tech-card-top {
    height: 10rem;
  }

  .tech-card-text {
    padding: 1rem 1rem 0 1rem;
    font-size: var(--fs-small);
  }

  .tech-card-text h3 {
    padding: 0.05rem 0.1rem 0.05rem 0.1rem;
  }

  .tech-icon {
    font-size: 6rem;
  }
}

@media(min-width: 768px){
  .tech-card {
    min-width: 13rem;
    min-height: 17rem;
    margin: 0 20px 0 0;
  }

  .tech-card-top {
    height: 13rem;
  }

  .tech-card-text {
    padding: 1rem 1rem 0 1rem;
    font-size: var(--fs-small);
  }

  .tech-card-text h3 {
    padding: 0.05rem 0.1rem 0.05rem 0.1rem;
  }

  .tech-icon {
    font-size: 7rem;
  }

}

@media(min-width: 1024px){
  .tech-card {
    min-width: 15rem;
    min-height: 25rem;
    margin: 0 20px 0 0;
  }

  .tech-card-top {
    height: 15rem;
  }

  .tech-card-text {
    padding: 1rem 1rem 0 1rem;
  }

  .tech-card-text h3 {
    padding: 0.05rem 0.1rem 0.05rem 0.1rem;
  }

  .tech-icon {
    font-size: 7rem;
  }
}

@media(min-width: 1200px){
  .tech-card {
    min-width: 15rem;
    min-height: 25rem;
    margin: 0 20px 0 0;
  }

  .tech-card-top {
    height: 15rem;
  }

  .tech-card-text {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }

  .tech-card-text h3 {
    padding: 0.05rem 0.1rem 0.05rem 0.1rem;
  }

  .tech-icon {
    font-size: calc(var(--fs-huge)*1.5);
  }
}
