.cursor-container {
  display: flex;
  align-content: center;
  margin-left: 1rem;
}

.cursor {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
}

