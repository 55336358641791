.nav-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: var(--side-margin);

}

.nav-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    padding: 0;
}

.nav-list {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    position: relative;
    color: var(--light);
}

.nav-link {
    text-decoration: none;
    font-family: 'SuisseIntlMono-Regular';
    font-size: var(--fs-small);
}

.underline {
    position: absolute;
    bottom: -0.3rem;
    left: 0;
    right: 0;
    height: 0.15rem;
    background: var(--primary-lightest);
}

.selected {
    color: white;
}


