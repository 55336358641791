.social-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--text-pad);
    padding: 3rem 0 3rem 0;
}

.item-content {
    display: flex;
    align-items: center;
    justify-content: center;
    border: var(--grey-00) solid var(--lines);
    border-radius: var(--border-radius);
    overflow: hidden;
    user-select: none;
    background: transparent;
}

.btn-text {
    color: var(--grey-00);
    font-family: 'SuisseIntlMono-Light';
}

@media(min-width: 320px){
    social-container {
        width: 80vw;
    }

    .item-content {
        width: 30vw;
        height: 4vh;
    }
}

@media(min-width: 480px){
    .social {
        width: 80vw;
    }

    .item-content {
        width: 25vw;
        height: 4vh;
    }
}

@media(min-width: 768px){
    .social {
        width: 60vw;
    }

    .item-content {
        width: 20vw;
        height: 4vh;
    }
}

@media(min-width: 1024px){
    .social {
        width: 60vw;
    }

    .item-content {
        width: 15vw;
        height: 4rem;
    }
}

@media(min-width: 1200px){
    .social {
        width: 60vw;
    }

    .item-content {
        width: 11vw;
        height: 4rem;
    }
}
