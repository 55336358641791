.container {
  display: flex;
  flex-direction: column;
  top: 10vh;
  gap: 2vh;
  padding-top: 15vh;
}

aside {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--grey-80);
}

.btn-container {
  position: absolute;
  z-index: 10;
  top: 0;
  right: var(--side-margin);
  bottom: 0;
  cursor: pointer;
  background-color: transparent; 
  border: none;
}

.nav-item {
  text-decoration: none;
  margin-left: var(--side-margin);
}

.link-number {
  font-family: SuisseIntlMono-Bold;
  font-size: 15vw;
}

.link-text {
  font-family: SuisseIntlMono-Light;
  font-size: 11vw;
}
