.list-cont.list-container {
  display: flex;
  flex-wrap: wrap;
}

.list-item {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 0 var(--text-pad) 0 var(--text-pad);
  border-radius: var(--border-radius);
  background-color: var(--elevation-1);
}

.list-pic {
  display: flex;
  align-self: flex-start;
  max-height: auto;
  width: 100%;
  height: 200px;
  padding: 3rem 2rem 2rem 0;
}

svg {
  width: 100%;
  height: auto;
}

.list-pic svg {
  width: 100%;
  height: 100%;
}

.tags-container {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.list-tags {
  list-style-type: none;
  font-family: SuisseIntlMono-Light;
  font-size: var(--fs-tiny);
  padding: 0 0.5rem 0 0.5rem;
  border-radius: var(--border-radius);
  border: solid var(--highlight-1) var(--border-width);
}

@media(min-width: 320px) {
  .list-item {
    margin-bottom: 1rem;
    width: auto;
  }

  .list-pic {
    height: 130px;
    padding: 2rem 2rem 2rem 0;
  }
}

@media(min-width: 480px) {
  .list-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .list-item {
    width: auto;
    margin-bottom: 0.5rem;
  }

  .list-pic {
    height: 120px;
    padding: 2rem 2rem 2rem 0;
  }
}

@media(min-width: 768px) {
  .list-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .list-pic {
    height: 150px;
    padding: 2rem 2rem 2rem 0;
  }
}

@media(min-width: 1024px) {
  .list-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }

  .list-pic {
    height: 150px;
    padding: 2rem 2rem 2rem 0;
  }
}

@media(min-width: 1200px) {
  .list-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  }

  .list-item {
    min-height: 25rem;
  }

  .list-pic {
    height: 130px;
    padding: 3.5rem 2rem 3rem 0;
  }

  .tags-container {
    padding-bottom: 0.5rem;
  }
}
